import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Home } from './pages/home';
import { NotFound } from './pages/404';
import { Privacy } from './pages/privacy';
import { CookieConsent } from './CookieConsent';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <CookieConsent />
      <Routes>
        <Route index element={<Home />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/404' element={<NotFound />} />
        <Route path='*' element={<Navigate replace to='/404' />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
