import { Footer } from '../../components/footer';

export const Home = () => {
  return (
    <div>
      {/* section1 start */}
      <div
        className={`bg-hero bg-cover bg-repeat py-10 lg:h-[100vh] flex flex-col  justify-center`}
      >
        <div className=' flex flex-col lg:flex-row justify-between items-center  max-w-4xl xl:max-w-5xl mx-auto w-full'>
          {/* left */}
          <div className='flex flex-col items-center space-y-3 lg:items-start mx-auto mb-[2.5rem] lg:mb-0'>
            <div className='flex items-center gap-[1.5rem]'>
              <img
                src='/assets/app-icon.png'
                className='w-[4.5rem] md:w-[7.875rem]'
              />

              <h1 className='text-[3.075rem]  md:text-[4rem] xl:text-[6rem] font-limelight text-[#FFF]'>
                City Spy
              </h1>
            </div>
          </div>
          {/* right */}
          <div className='flex flex-col items-center mx-auto'>
            <img
              src='/assets/lp-screenshot-1.png'
              className='w-[18.5rem] md:w-[21.25rem] mb-[2.5rem]'
            />
            <a href={process.env.REACT_APP_APP_STORE_URL} target='_blank'>
              <img
                src='/assets/app_store_badge_en.svg'
                className='w-44 min-h-[40px] h-14 mb-[1rem]'
              />
            </a>
            <a
              href={process.env.REACT_APP_GOOGLE_PLAY_STORE_URL}
              target='_blank'
            >
              <img
                src='/assets/google_play_badge_en.png'
                className='w-44 min-h-[40px]'
              />
            </a>
          </div>
        </div>
      </div>

      {/* section1 end */}

      {/* section2 start */}

      <div className='bg-[#E2D3AB] py-10'>
        <div className=' flex flex-col lg:flex-row justify-between items-center  max-w-4xl xl:max-w-6xl mx-auto space-y-[5rem] lg:space-y-0'>
          {/* left */}
          <div className='flex flex-col items-start space-y-3 md:items-start mx-auto px-3  max-w-md'>
            <p className='font-bold  text-[2.625rem] lg:text-5xl text-[#000] font-limelight'>
              You are a spy!
            </p>
            <p className=' text-[1.125rem] lg:text-2xl text-[#000] font-rowdies'>
              When you play City Spy, you are sent to several different cities.
              Each city has 22 key locations, of which three are specified as
              your mission targets.
            </p>
          </div>
          {/* right */}
          <div className='flex flex-col space-y-3 items-center mx-auto'>
            <img
              src='/assets/lp-screenshot-2.png'
              className='w-[18.5rem] md:w-[21.25rem]'
            />
          </div>
        </div>
      </div>

      {/* section2 end */}

      {/* section3 start */}

      <div className='bg-[#F3EDDC] py-10'>
        <div className=' flex flex-col lg:flex-row justify-between items-center  max-w-4xl xl:max-w-6xl mx-auto space-y-[5rem] lg:space-y-0'>
          {/* left */}
          <div className='flex flex-col items-start space-y-3 md:items-start mx-auto px-3 max-w-md'>
            <p className='font-bold text-[2.625rem] lg:text-5xl text-[#000] font-limelight'>
              explore cities
            </p>
            <p className='text-[1.125rem] lg:text-2xl text-[#000] font-rowdies'>
              You can find out the name of each location on the map by tapping
              on it. However, you can only move to locations that are fairly
              close to you, so you have to do a bit of route planning to get to
              your target locations.
            </p>
          </div>
          {/* right */}
          <div className='flex flex-col space-y-3 items-center mx-auto'>
            <img
              src='/assets/lp-screenshot-3.png'
              className='w-[18.5rem] md:w-[21.25rem]'
            />
          </div>
        </div>
      </div>

      {/* section3 end */}

      {/* section4 start */}

      <div className='bg-[#E2D3AB] py-10'>
        <div className=' flex flex-col lg:flex-row justify-between items-center  max-w-4xl xl:max-w-6xl mx-auto space-y-[5rem] lg:space-y-0'>
          {/* left */}
          <div className='flex flex-col items-start space-y-3 md:items-start mx-auto px-3 max-w-md'>
            <p className='font-bold text-[2.625rem] lg:text-5xl text-[#000] font-limelight'>
              Answer questions
            </p>
            <p className='text-[1.125rem] lg:text-2xl text-[#000] font-rowdies'>
              Once you enter a target location, you have to answer 5 out of 8
              questions correctly within the time given, in order to win a
              prize. Note that you may already have the prize offered. To finish
              City Spy completely, you need to collect at least one each of all
              the available prizes (initially 12 prizes).
            </p>
          </div>
          {/* right */}
          <div className='flex flex-col space-y-3 items-center mx-auto'>
            <img
              src='/assets/lp-screenshot-4.png'
              className='w-[18.5rem] md:w-[21.25rem]'
            />
          </div>
        </div>
      </div>

      {/* section4 end */}

      {/* section5 start */}

      <div className='bg-[#F3EDDC] py-10'>
        <div className=' flex flex-col lg:flex-row justify-between items-center  max-w-4xl xl:max-w-6xl mx-auto space-y-[5rem] lg:space-y-0'>
          {/* left */}
          <div className='flex flex-col items-start space-y-3 md:items-start mx-auto px-3  max-w-md'>
            <p className='font-bold text-[2.625rem] lg:text-5xl text-[#000] font-limelight'>
              complete missions
            </p>
            <p className='text-[1.125rem] lg:text-2xl text-[#000] font-rowdies'>
              If you quit, you don’t get to keep any prizes. If you quit, or
              successfully complete your mission (collect three items), you will
              be able to retry the map, or move on to another mission on a
              different map.
            </p>
          </div>
          {/* right */}
          <div className='flex flex-col space-y-3 items-center mx-auto'>
            <img
              src='/assets/lp-screenshot-5.png'
              className='w-[18.5rem] md:w-[21.25rem]'
            />
          </div>
        </div>
      </div>

      {/* section5 end */}

      {/* section6 start */}
      <div className='bg-[#8C7E6D] flex flex-col items-center py-10 gap-[2rem] lg:gap-[3.5rem]'>
        <p className='text-[#F3EDDC] font-limelight text-[3.375rem] lg:text-[4rem]'>
          City Spy
        </p>

        <img src='/assets/app-icon.png' className='w-[10rem]' />

        <div className='flex flex-col space-y-5 lg:flex-row lg:space-y-0 lg:space-x-5 lg:items-center'>
          <a href={process.env.REACT_APP_APP_STORE_URL} target='_blank'>
            <img
              src='/assets/app_store_badge_en.svg'
              className='w-44 min-h-[40px] h-14'
            />
          </a>
          <a href={process.env.REACT_APP_GOOGLE_PLAY_STORE_URL} target='_blank'>
            <img
              src='/assets/google_play_badge_en.png'
              className='w-44 min-h-[40px]'
            />
          </a>
        </div>
      </div>

      {/* section6 end */}

      {/* section7 start */}
      <div className='bg-gradient-to-r from-green-500 via-purple-500 to-indigo-500 flex flex-col items-center py-10 gap-[2rem] lg:gap-[3.5rem]'>
        <div className='flex flex-col items-center'>
          <p className='text-[#FFF] font-bungee text-[2.625rem] lg:text-[4rem]'>
            Global Spy
          </p>
          <p className='font-limelight text-[#FFF] max-w-md lg:max-w-full text-center'>
            Don’t forget to try our other game, Global Spy! These are the two
            best spy games for mobile devices.
          </p>
        </div>
        <img src='/assets/gs-app-icon.png' className='w-[9.375rem]' />

        <div className='flex flex-col space-y-5 lg:flex-row lg:space-y-0 lg:space-x-5 lg:items-center'>
          <a href={process.env.REACT_APP_APP_STORE_URL_GS} target='_blank'>
            <img
              src='/assets/app_store_badge_en.svg'
              className='w-44 min-h-[40px] h-14'
            />
          </a>
          <a
            href={process.env.REACT_APP_GOOGLE_PLAY_STORE_URL_GS}
            target='_blank'
          >
            <img
              src='/assets/google_play_badge_en.png'
              className='w-44 min-h-[40px]'
            />
          </a>
        </div>
      </div>

      {/* section7 end */}

      <Footer />
    </div>
  );
};
