import { Footer } from '../../components/footer';

export const Privacy = () => {
  return (
    <div className='bg-[#F3EDDC]'>
      <div className='py-10 flex flex-col items-center'>
        <img
          src='/assets/app-icon.png'
          className='w-[5rem] md:w-[7.875rem] mt-[1.5rem]'
        />
        <h1 className='text-[1.5rem] font-limelight text-[#141414] mt-[0.5rem]'>
          City Spy
        </h1>
        <div className='max-w-4xl px-[1rem] pt-[5rem]'>
          <p className='text-[2.25rem] font-roboto font-[900] leading-10 text-left mb-[2.5rem]'>
            Yuyosoft Innovations Inc.
            <br />
            Terms of Use & Privacy Policy
          </p>

          <div className='mb-[2.5rem]'>
            <p className='font-roboto text-[1.875rem] font-[700] leading-10 mb-[1.5rem]'>
              Who we are
            </p>

            <p className='font-roboto leading-6'>
              This Terms of Use & Privacy Policy covers websites, apps and games
              published by Yuyosoft Innovations Inc. Our website address is:{' '}
              <a
                href='https://yuyosoft.com'
                target='_blank'
                rel='noopener noreferrer'
                className='underline cursor-pointer'
              >
                https://yuyosoft.com
              </a>
            </p>
          </div>

          <div className='mb-[2.5rem]'>
            <p className='font-roboto text-[1.875rem] font-[700] leading-10 mb-[1.5rem]'>
              No responsibility for loss or damage
            </p>

            <p className='font-roboto leading-6'>
              By using our websites, apps, games or services, whether standalone
              or in connection with our servers, you are doing so subject to
              this Terms of Use & Privacy Policy. Yuyosoft Innovations Inc.
              accepts no responsibility for direct or indirect loss or damage as
              a result of your using our websites, apps and games, and you do so
              at your own risk.
            </p>
          </div>

          <div className='mb-[2.5rem]'>
            <p className='font-roboto text-[1.875rem] font-[700] leading-10 mb-[1.5rem]'>
              Service interruptions
            </p>

            <p className='font-roboto leading-6'>
              The services we provide, e.g. e-mail newsletters or servers for
              client-server apps and games, may have a schedule of delivery, but
              in the uncommon event of unforeseen network or technical issues,
              we may be unable to meet the schedule, and therefore do not
              guarantee any particular level of service. In such cases we will
              attempt to restore regular service as soon as possible.
            </p>
          </div>

          <div className='mb-[2.5rem]'>
            <p className='font-roboto text-[1.875rem] font-[700] leading-10 mb-[1.5rem]'>
              What personal data we collect and why we collect it
            </p>

            <p className='font-roboto text-[1.5rem] font-[700] mb-[1rem]'>
              Apps and games
            </p>

            <p className='font-roboto leading-6 mb-[1.5rem]'>
              None of our websites, apps or games collects or stores personal
              data and information of any kind, with the exception of a valid
              e-mail address in the case of apps or games that require
              registration to identify you as a user in a multi-user
              environment, or order information for e-commerce.
            </p>

            <p className='font-roboto text-[1.5rem] font-[700] mb-[1rem]'>
              Contact forms
            </p>

            <p className='font-roboto leading-6 mb-[1.5rem]'>
              In our websites, apps and games, we may sometimes provide forms
              for you to use in contacting us for support. The data that you
              yourself input into these forms is stored on our servers, so that
              we can reply to you and provide you with the support that you
              require.
            </p>
            <p className='font-roboto text-[1.5rem] font-[700] mb-[1rem]'>
              Cookies
            </p>

            <p className='font-roboto leading-6 mb-[1.5rem]'>
              If you have an account and you log in to our websites or apps, we
              may use temporary cookies, according to your preferences, to save
              your login information, game progress, screen display choices,
              etc. In particular, if you edit or publish any content on our
              website, cookies may be saved in your browser to identify you as
              the editor or author. This cookie includes no personal data such
              as address or phone number–it only identifies the user account and
              ID of your article.
            </p>
            <p className='font-roboto text-[1.5rem] font-[700] mb-[1rem]'>
              Embedded content from other websites
            </p>

            <p className='font-roboto leading-6 mb-[1.5rem]'>
              Content on our websites may include embedded content (e.g. videos,
              images, articles, etc.). Embedded content from other websites
              behaves in the exact same way as if the visitor has visited the
              other website. Please be aware that we are not responsible for the
              Terms of Use & Privacy Policy of those websites, and your use of
              any account you may have with those websites.
            </p>
            <p className='font-roboto text-[1.5rem] font-[700] mb-[1rem]'>
              Analytics
            </p>

            <p className='font-roboto leading-6 mb-[1.5rem]'>
              On our website, analytics may be run on anonymous usage data for
              the purpose of marketing statistics, debugging and optimization.
            </p>
          </div>

          <div className='mb-[2.5rem]'>
            <p className='font-roboto text-[1.875rem] font-[700] leading-10 mb-[1.5rem]'>
              No sharing of data
            </p>

            <p className='font-roboto leading-6'>
              We do not sell, rent or trade your personal data with any third
              party.
            </p>
          </div>
          <div className='mb-[2.5rem]'>
            <p className='font-roboto text-[1.875rem] font-[700] leading-10 mb-[1.5rem]'>
              How long we retain your data
            </p>

            <p className='font-roboto leading-6 mb-[1rem]'>
              User content and comments on our websites and apps, once
              published, are retained indefinitely.
            </p>

            <p className='font-roboto leading-6'>
              For users that register on our websites and apps, we also store
              personal information you provide during registration and in
              updates of your user profile. You can see, edit (where available),
              or delete your personal information at any time (except you cannot
              change your username or e-mail if they identify you as a unique
              user in a multi-user space).
            </p>
          </div>
          <div className='mb-[2.5rem]'>
            <p className='font-roboto text-[1.875rem] font-[700] leading-10 mb-[1.5rem]'>
              What rights you have over your data
            </p>

            <p className='font-roboto leading-6 mb-[1rem]'>
              If you have an account on our websites or mobile apps, you can
              also request that we erase the personal data we hold about you.
              This will result in the closing of your account, and does not
              include any data we are obliged by law to keep for accounting,
              legal, or security purposes.
            </p>

            <p className='font-roboto text-[1.5rem] font-[700] mb-[1rem]'>
              All rights reserved
            </p>

            <p className='font-roboto leading-6'>
              All content in our websites and apps are protected by copyright
              and other rights. You may view the content within our websites and
              apps, but ownership and rights to use these elsewhere are not
              transferred to you.
            </p>
          </div>

          <div className='mb-[2.5rem]'>
            <p className='font-roboto text-[1.875rem] font-[700] leading-10 mb-[1.5rem]'>
              Contact information
            </p>

            <p className='font-roboto leading-6 mb-[1rem]'>
              All enquiries regarding this Terms of Use & Privacy Policy should
              be directed to Yuyosoft Innovations Inc., N400-5811 Cooney Road,
              Richmond BC, Canada V6X 3M1 or info@yuyosoft.com.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
